import React  from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Section from "../../components/template-partials/global-components/section"
import InteriorMenu from "../../components/template-partials/global-components/interior-menu"
import InteriorPageHeader from "../../components/template-partials/global-components/interior-page-header"
import SupportAndCommunitySubMenu from "../../components/template-partials/support-and-community/support-and-community-submenu"

import headingBg from "../../images/7.0-headerbg.jpg"
import headingRound from "../../images/resources-and-support-nav-image.jpg"
import { Link } from "gatsby"

const ThankYou = () => {
  return (
    <Layout>
      
      <SEO title="Neuroblastoma Questions to Ask Your Oncologist" description="Your child’s healthcare team is your greatest asset in learning about neuroblastoma and understanding each step along the treatment journey. Use our question builder to help facilitate informed discussions about your child’s care." />

      <InteriorPageHeader backgroundImage={headingBg} roundImage={headingRound} alt="Order Resources">
        <h1 className="section__heading section__heading--green h1">Order Resources</h1>
      </InteriorPageHeader>

      {/* style={{backgroundColor: SassVars.viridian}} */}
      <Section className="interior-page">        
        <div className="row row--inner">
          <div className="columns">
            <h2 className="tc-viridian h2 jost-med">Thank you for submitting your request.</h2>
            <p>You can find these and additional resources to support you on your journey on our <Link to="/support-and-community/downloadable-resources/">Downloadable Resources and Videos</Link> page.</p>
          </div>
        </div>
      </Section>
    </Layout>
  )
}

export default ThankYou
